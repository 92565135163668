/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function address(profile) {
  var output = profile.address.line1 + ", ";
  if(profile.address.line2) {
    output += profile.address.line2 + ", "
  }
  output += profile.address.city + ", " + profile.address.region + " " + profile.address.postalCode;
  output += ' (<a class="HitchhikerProfessionalLocation-addressLink" href="{{card.addressLink}}">Map</a>)';
  return output;
}
